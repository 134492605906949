import { IPrescription, IDocument } from '@/interfaces';
import { PrescriptionState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    clearPrescriptions(state: PrescriptionState) {
        state.prescriptions = []
    },
    setPrescriptions(state: PrescriptionState, payload: IPrescription[]) {
        let prescriptions = state.prescriptions;
        payload.forEach(
            (p_in) => {
                prescriptions = prescriptions.filter((p) =>p.id != p_in.id);
                prescriptions.push(p_in);
            }
        )
        state.prescriptions = prescriptions;
    },
    setPrescription(state: PrescriptionState, payload: IPrescription) {
        const prescriptions = state.prescriptions.filter((prescription: IPrescription) => prescription.id !== payload.id);
        prescriptions.push(payload);
        state.prescriptions = prescriptions;
    },
    removePrescription(state: PrescriptionState, payload: number) {
        state.prescriptions = state.prescriptions.filter((prescription: IPrescription) => prescription.id !== payload);
    },
    setDocumentsForPrescription(state: PrescriptionState, payload: {prescriptionId: number, docs: IDocument[]}) {

        const prescriptions = state.prescriptions.filter((prescription: IPrescription) => prescription.id !== payload.prescriptionId);
        let prescription = state.prescriptions.filter((prescription: IPrescription) => prescription.id == payload.prescriptionId)[0];
        prescription.documents = payload.docs
        prescriptions.push(prescription);
        state.prescriptions = prescriptions;
    },
};

const { commit } = getStoreAccessors<PrescriptionState, State>('');

export const commitSetPrescription = commit(mutations.setPrescription);
export const commitClearPrescriptions = commit(mutations.clearPrescriptions)
export const commitSetPrescriptions = commit(mutations.setPrescriptions);
export const commitRemovePrescription = commit(mutations.removePrescription);
export const commitSetDocumentsForPrescription = commit(mutations.setDocumentsForPrescription)