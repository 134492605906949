import { IAttestationWithAppointments } from './interfaces';
import { IDocument } from '@/interfaces';
import { AttestationState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setAttestations(state: AttestationState, payload: IAttestationWithAppointments[]) {
        state.attestations = payload;
    },
    setAttestation(state: AttestationState, payload: IAttestationWithAppointments) {
        const attestations = state.attestations.filter((attestation: IAttestationWithAppointments) => attestation.id !== payload.id);
        attestations.push(payload);
        state.attestations = attestations;
    },
    setDocumentsForAttestation(state: AttestationState, payload: {attestationId: number, docs: IDocument[]}) {

        const attestations = state.attestations.filter((attestation: IAttestationWithAppointments) => attestation.id !== payload.attestationId);
        let attestation = state.attestations.filter((attestation: IAttestationWithAppointments) => attestation.id == payload.attestationId)[0];
        attestation.documents = payload.docs
        attestations.push(attestation);
        state.attestations = attestations;
    },
};

const { commit } = getStoreAccessors<AttestationState, State>('');

export const commitSetAttestations = commit(mutations.setAttestations);
export const commitSetAttestation = commit(mutations.setAttestation);
export const commitSetDocumentsForAttestation = commit(mutations.setDocumentsForAttestation)